.cost-block {

	&__row {
		max-width: 41em;
		margin: 0 auto;
	}


	&__item {
		text-align: center;
		margin-bottom: 4em;
	}


	&__icon {
		background: $secondary-colour;
		height: em(91);
		width: em(91);
		display: inline-block;
		margin-bottom: em(35);
		border-radius: 100%;
		opacity: 0;
		transform: scale(.5);
		transition: all .4s ease-in-out;


		&.is-showing {
			opacity: 1;
			transform: scale(1);
		}

		span {
			display: inline-block;
			height: em(40);
			width: em(40);
			margin-top: 1.2em;
		}


		.therapy {
			@include bg-icon("paintbrus-icon");
		}


		.face-to-face {
			@include bg-icon("face-to-face-meeting-icon");
		}


		.duration {
			@include bg-icon("session-duration-icon");
		}
	}


	&__text {}


	&__price {
		font-size: em(34);
		font-weight: $fw-bold;
	}


	@include media(690px){
		&__item {
			@include span-columns(4);
			margin-bottom: 2em;
		}
	}

}