// Colours


$primary-colour: #c15b5b;
$secondary-colour: #efe7e7;
$tertiary-colour: #5da8ba;

$active-link-colour: #3f3f3f;
// button colour;
// text colour;



// Fonts

@font-face {
	font-family: 'Dosis';
	src: url('../fonts/matthew-clark/Dosis-ExtraBold.eot');
	src: url('../fonts/matthew-clark/Dosis-ExtraBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/matthew-clark/Dosis-ExtraBold.woff') format('woff'),
		url('../fonts/matthew-clark/Dosis-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('../fonts/matthew-clark/Dosis-Light.eot');
	src: url('../fonts/matthew-clark/Dosis-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/matthew-clark/Dosis-Light.woff') format('woff'),
		url('../fonts/matthew-clark/Dosis-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('../fonts/matthew-clark/Dosis-SemiBold.eot');
	src: url('../fonts/matthew-clark/Dosis-SemiBold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/matthew-clark/Dosis-SemiBold.woff') format('woff'),
		url('../fonts/matthew-clark/Dosis-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('../fonts/matthew-clark/Dosis-Bold.eot');
	src: url('../fonts/matthew-clark/Dosis-Bold.eot?#iefix') format('embedded-opentype'),
		url('../fonts/matthew-clark/Dosis-Bold.woff') format('woff'),
		url('../fonts/matthew-clark/Dosis-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('../fonts/matthew-clark/Dosis-Regular.eot');
	src: url('../fonts/matthew-clark/Dosis-Regular.eot?#iefix') format('embedded-opentype'),
		url('../fonts/matthew-clark/Dosis-Regular.woff') format('woff'),
		url('../fonts/matthew-clark/Dosis-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('../fonts/matthew-clark/Dosis-ExtraLight.eot');
	src: url('../fonts/matthew-clark/Dosis-ExtraLight.eot?#iefix') format('embedded-opentype'),
		url('../fonts/matthew-clark/Dosis-ExtraLight.woff') format('woff'),
		url('../fonts/matthew-clark/Dosis-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Dosis';
	src: url('../fonts/matthew-clark/Dosis-Medium.eot');
	src: url('../fonts/matthew-clark/Dosis-Medium.eot?#iefix') format('embedded-opentype'),
		url('../fonts/matthew-clark/Dosis-Medium.woff') format('woff'),
		url('../fonts/matthew-clark/Dosis-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}


$em-base: 20px;

$body-font: "Dosis", sans-serif;


$fw-extra-light: 200;
$fw-light: 300;
$fw-regular: normal;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
$fw-extra-bold: 800;





// Sizing units