html,
body {
  font-size: $em-base;
}

body {
  line-height: 1.3;
  border-left: em(8) solid $primary-colour;
  border-right: em(8) solid $primary-colour;
  font-family: $body-font;
  margin-top: em(164);

  @include media(1050px) {
	margin-top: em(132);
  }

}

img, video {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0 0 1.5em;
  line-height: 1.7;
}

a {
  color: inherit;
}

h2 {
  margin: 0 0 em(26);
  font-weight: normal;
}

blockquote {
  margin: 0 auto;
}