
@mixin bg($filename) {
  background-image: url('../images/matthew-clark/#{$filename}.svg');
          
  .ie7 &, .ie8 &, .firefox3 &, .safari-5 & {
    background-image: url('../imagesmatthew-clark//#{$filename}.png');
  }      
}


@mixin bg-icon($filename) {
    background:transparent url('../images/matthew-clark/#{$filename}.svg') center no-repeat;
    background-size: contain;
          
  .ie7 &, .ie8 &, .firefox3 &, .safari-5 & {
    background:transparent url('../images/matthew-clark/#{$filename}.png') center no-repeat;
    background-size: contain;
  }      
}



@mixin omega-reset($nth) {
  &:nth-child(#{$nth}) { margin-right: flex-gutter(); }
  &:nth-child(#{$nth}+1) { clear: none }
}





@mixin subtle-shadow(){
  box-shadow: 0.15em 0.15em 0.1em 0 rgba(212, 212, 212, 0.57);
}


@mixin bg-pattern($filename) {
    background:transparent url('../images/matthew-clark/#{$filename}') center repeat;
}