.desktop-navigation {
	margin-left: em(190);

	&__list {
		padding: 0;
		list-style: none;
		text-align: right;
		margin: 0;


	  li
	  {
		display: inline-block;
		margin-right: 5%;
		text-align: center;
		position: relative;

		&:after {
		  content: "";
		  display: inline-block;
		  background: $active-link-colour;
		  height: em(2);
		  width: 0;
		  position: absolute;
		  bottom: -0.2em;
		  left: 50%;
		  transform: translateX(-50%);
		  transition: width .2s ease-in-out;
		}


		&:hover,
		&.active {
		  &:after {
			width: em(33);
		  }
		}


		&:last-child {
		  margin-right: 0;
		}

		a {
		  font-weight: $fw-medium;
		  display: inline-block;

		  &.active {
			color: $primary-colour;
		  }
		}
	  }
	}


}