.quote-slide {
  background: $secondary-colour;
  padding: em(85) em(20);
  overflow: hidden;
  position: relative;
  text-align: center;

  .slide-inner {
	max-width: em(1100);
	margin: 0 auto;
  }



  &__list {
  }


  &__quote-item {
	display: inline-block;
	width: 100%;
	text-align: center;
	padding: 0 3em;
  }

  &__quote-title {
	white-space: normal;
	margin-bottom: em(5);
	color: $primary-colour;
  }


  &__quote-para {
	font-size: em(22);
	font-weight: $fw-bold;
	text-align: center;
	line-height: 1.5;
	margin: 0 auto;
	//font-family: $header-font;

	&:before {
	  content: "“";
	}


	&:after {
	  content: "”";
	}
  }



  &__dots {
	text-align: center;
	color: pink;
	clear: both;

	span {
	  font-size: 2em;
	  margin-right: 0.1em;
	  cursor: pointer;

	  &:last-child {
		margin-right: 0;
	  }
	}

	.cycle-pager-active {
	  color: $primary-colour;
	}
  }


  &__quote-cite {
	display: block;
	font-weight: normal;
	font-style: normal;
	margin: em(30, 22) 0 em(50, 22);
  }



  &__prev {
	cursor: pointer;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: 300;
	display: inline-block;
	height: 3em;
	width: 3em;
	@include bg-icon("left-arrow");
  }


  &__next {
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	z-index: 300;
	display: inline-block;
	height: 3em;
	width: 3em;
	@include bg-icon("right-arrow");
  }

  @include media(940px){
	&__list {

	}

	&__quote-item {
	  // width: 33%;
	}
  }
}
