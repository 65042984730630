
.red-bullet-list {
  list-style: none;
  margin-bottom: 1.5em;
  padding-left: 0;
  li {
	margin-bottom: em(30);
	line-height: 1.6;

	&:before {
	  content: "";
	  background: $primary-colour;
	  display: inline-block;
	  height: 0.3em;
	  width: 0.3em;
	  border-radius: 100%;
	  margin-right: 0.4em;
	}
  }
}

.tabbed-area {
	background: white;
	padding-bottom: em(80);



	&__nav {
		@include bg-pattern('pattern.png');
		@include row();
	}




	&__nav-item {
		list-style: none;
		float: left;
		width: 19%;
		margin-right: 1%;
		text-align: center;
		background: $secondary-colour;
		color: $primary-colour;
		cursor: pointer;
		padding: 0.5em 0 0.5em 0;

		&.active {
			background: white;
		}


		&:last-child {
			margin-right: 0;
		}


		@include media(880px){
			width: 9.1%;
			margin-right: 1%;
		}
	}


	&__content {
		max-width: 41em;
		margin: 0 auto;
		padding: em(50) em(25);
		transition: opacity .2s ease-in-out;
		display: none;

		> img {
			margin-bottom: 1.5em;
		}


		p a {
			color: $primary-colour;
		}

	  ul
	  {
		@extend .red-bullet-list;
	  }


		&.active {
			display: block;
		}
	}
}














// For responsive youtube embed

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}



.youtube-caption {
	text-align: center;
	margin-top: -0.7em;


	&__line {
		font-size: 1.3em;
	}
}


.footnote {
	font-size: em(18);
}