/* 
This is a floating slide.

Usually is the second slide on a page and it floats just above either
the slider on the index page or the big title slides on other pages

The html for it is:

	<section class="floating-slide has-pattern">
		<div class="container">
			<div class="floating-slide__box">
				<div class="floating-slide__box-inner">
					<h2 class="floating-slide__title">Brief welcome message here</h2>
					<p class="floating-slide__text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tenetur beatae minima modi pariatur provident cum optio obcaecati maiores fugit officia deleniti distinctio debitis dolore atque saepe dolor, quisquam consequatur alias?</p>
				</div>
			</div>
		</div>
	</section>

The has-pattern class is optional and adds a pattern if on the object

*/


.floating-slide {
	padding: 0 em(25) em(35);

	&__title {
		color: $primary-colour;
		text-align: center;
	}


	&__box {
		@include subtle-shadow();
		padding: em(35);
		background: white;
		position: relative;
		top: em(-80);
		margin-bottom: em(-80);
	}


	&__box-inner {
		max-width: em(820);
		margin-left: auto;
		margin-right: auto;
	}

	p:last-child,
	a:last-child { margin-bottom: 0; }
}
