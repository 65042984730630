// .mobile-navigation also has .js-mobile-navigation on it but it's just a hook for javascript it doesnt do any styling

.mobile-navigation {
  margin-left: em(-5);
  margin-right: em(-5);
  display: none;

  &__list {
	list-style: none;
	padding-left: 0;
	text-align: center;

	li {
	  background: $primary-colour;

	  a {
		font-weight: $fw-medium;
		font-size: em(26);
		display: block;
		color: white;
		padding: em(18, 26) 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	  }
	}
  }

}