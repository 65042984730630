.btn {
	@extend button;
	text-decoration: none; /* opinionated */
	transition: all .2s ease-in-out;

/* 	.button extends button in base-elements/forms, classes to add new types
of buttons should be added here */

	&:hover {
		background: $tertiary-colour;
		color: white;
		&:before {
			height: 100%;
		}
	}
}