.contact-block {


	&__row {
	  max-width: 41em;
	  margin: 0 auto;
	}


	&__item {

	  @include span-columns(4);
	  @include omega(2n);

	  margin-top: 3em;
	  margin-bottom: 3em;
		text-align: center;

	  &--email
	  {
		@include shift(2);
	  }

	}

	&__email {
		display: inline-block;
		border-bottom: 1px solid $primary-colour;
	}

	&__phone {
		display: inline-block;
		//border-bottom: 1px solid $primary-colour;
	}


	&__icon {
		background: $secondary-colour;
		height: em(91);
		width: em(91);
		display: inline-block;
		margin-bottom: em(35);
		border-radius: 100%;

		span {
			display: inline-block;
			height: em(40);
			width: em(40);
			margin-top: 1.2em;
		}


		.email {
			@include bg-icon("email-icon");
		}


		.phone {
			@include bg-icon("phone-icon");
		}



		.linkedin {
			@include bg-icon("linkedin-icon")
		}



	}


	&__text {
		a {
			color: $primary-colour;
		  text-decoration: none;
		}
	}



	@include media(max-width 750px){

		&__item {
			@include span-columns(12);
			margin-bottom: 2em;
		  &--email
		  {
			@include shift(0);
		  }
		}
	}
}