/* ==========================================================================
Headings
========================================================================== */

/* 
* The CSS below for the headings really looks like it should be abstracted out to 
* something more dry but the idea here is that it provides a super easy place to make
* any small tweaks that the specific project might require.
*
* The extra h7 class is just for common areas where text is small eg copyright in footer,
* it's smaller than the body text.
* 
* The .h1 - .h7 classes only imitate the sizing on h1 - h6 elements, not the font properties.
*/

h1, h2, h3,
h4, h5, h6 {
  // font-family: $header-font;
  // font-weight: $header-font-weight;
}

h1, .h1 {
  font-size: rem(30);
  line-height: 1.2;
  font-weight: $fw-bold;
  // margin: 0 0 $base-space-unit;
}

h2, .h2 {
  font-size: rem(34);
  margin: 0 0 rem(32);
  line-height: 1.2;
  font-weight: $fw-bold;
}

h3, .h3 {
  font-size: rem(28);
  line-height: 1.3;
  margin: 0 0 em(18, 28);
}

h4, .h4 {
  font-size: 1rem;
  line-height: 1.3;
  margin: 0 0 em(32);
  color: $primary-colour;
}

/* ==========================================================================
Basic Text Elements
========================================================================== */

p { margin: 0 0 1.5em; }

hr { margin: 0 0 1.5em; }

a {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}


// move later if anyone ever sees this.

section {

  a {
	color: $primary-colour;
	text-decoration: underline;
  }
}

/* ==========================================================================
Lists
========================================================================== */

ul, ol {
  margin-top: 0;
  // margin-bottom: 1.5em;

  li {
	// margin-bottom: 1.5em * 0.5;

	ul, ol {
	  // margin-top: 1.5em * 0.5;
	  // margin-bottom: 1.5em;
	}
  }
}