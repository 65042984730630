/* 
This is a basic slide found all over the site */
.slide {
	padding: em(40) em(25) em(40);

  	&.slide-thin
	{
	  padding: em(10) em(25) em(10);
	}

	.container {}

	&__title {
		text-align: center;
		color: $primary-colour;
	}

	&.is-pink { background: $secondary-colour; }



	p:last-child,
	a:last-child { margin-bottom: 0; }


	
	&.has-blocks-below { padding-bottom: em(150); }
}