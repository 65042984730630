.thin-image-slide {
	@include bg-pattern('pattern.png');
	position: relative;

	&__image {
		height: em(400);
		background:transparent url('https://www.placecage.com/700/600') center no-repeat;
		background-size: cover;
		background-attachment: fixed;
		position: relative;	

		&:after {
			content: "";
			display: inline-block;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: $primary-colour;
			opacity: .5;
		}
	}


}