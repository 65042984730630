.global-footer {
	background: $primary-colour;
	font-size: em(16);
	padding: em(35, 16) em(25, 16);
	font-weight: $fw-medium;
	color: white;
	position: relative;


	.scroll-up {
		display: inline-block;
		height: 2em;
		width: 2em;
		position: absolute;
		right: em(25);
		top: em(35);
		@include bg-icon("up-arrow");
		cursor: pointer;
	}


	.container {
		max-width: em(1200, 16);
	}



	&__links {
		text-align: center;
		span, a {
			display: block;
			margin-bottom: em(30, 16);

			&:last-child {
				margin-bottom: em(40, 16);
			}
		}

		span {
			font-weight: $fw-bold;
		}
	}



	&__email {
		display: inline-block !important;
		border-bottom: 1px solid white;
		padding-bottom: 0.35em;
	} 






	&__site-links {
		text-align: center;

		a {
			display: inline-block;
			margin: 0 1em em(40, 16);
		}
	}




	&__copy {
		font-weight: normal;
		padding-top: em(35, 16);
		font-size: em(14, 16);
		border-top: 1px solid rgba(255, 255, 255, 0.1);
	}



	&__copyright {

	}


	&__arise-link {
		float: right;
	}




	@include media(745px){
		&__links {
			text-align: left;
			span, a {
				display: inline-block;
				margin-right: 2em;

				&:last-child {
					margin-right: 0;
				}
			}
		}



		&__site-links {
			text-align: left;

			a {
				margin: 0 2.3em 2.5em 0;

				&:last-child {
					margin-right: 0 !important;
				}
			}
		}
	}
}