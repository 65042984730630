.page-header {
	padding: em(40) em(25) em(150);

	background: $primary-colour;
	color: white;
	text-align: center;

	&__title {
		font-size: em(50);
	}


	&__info {
		margin-bottom: 0;
	}
}