/* At bottom of every page



<section class="slide has-pattern">
	<div class="container">
		<div class="accreditation-block">
			<div class="inner-container">
				<h2 class="slide__title">Accreditations</h2>
				<p class="slide__text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias hic, in eligendi, asperiores eos minima earum saepe id cumque debitis provident commodi a quaerat architecto placeat adipisci accusantium suscipit ad.</p>

				<div class="two-image-block row">
					<span class="two-image-block__image"></span>
					<span class="two-image-block__image"></span>
				</div>
			</div>
		</div>
	</div>
</section>


 */



.accreditation-block {
	background: white;
	padding: em(60) em(25);
}