.work-examples {



	&__row {

	}




	&__box {
		height: em(370);
		background: pink;
		margin-bottom: 1.4em;
		position: relative;
		cursor: pointer;
		top: 3em;
		opacity: 0;
		transition: all .2s ease-in-out;


		&:hover,
		&:focus {
			.work-examples__overlay {
				opacity: 1;
			}
		}



		&.is-showing {
			top: 0;
			opacity: 1;
		}
	}



	&__overlay {
		position: absolute;
		top: 50%;
		left: 1em;
		right: 1em;
		opacity: 0;
		transform: translateY(-50%);
		text-align: center;
		transition: all .2s ease-in-out;
	}


	&__overlay-content {
		background: white;
		padding: em(25);
		height: 100%;
		max-width: 20em;
		display: inline-block;
	}



	&__title {
		text-align: center;
	}



	&__text {
		margin-bottom: 0;
	}



	@include media(890px){
		&__box {
			@include span-columns(6);
			@include omega(2n);
		}


		&__overlay-content {
			max-height: 15em;
		}
	}
}