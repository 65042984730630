.container {
	@include outer-container(em(1200));
}

.row {
	@include row();
}

.inner-container {
	max-width: em(820);
	margin: 0 auto;
}