.has-pattern {
	@include bg-pattern('pattern.png');
}

.u-text-center {
	text-align: center !important;
}

.u-full-width {
	width: 100%;
	height: auto;
}