.client-profile {
	margin-bottom: em(30);

	&__info {
		@include bg-pattern('pattern.png');
		padding: em(30);
		font-weight: $fw-bold;
	}




	&__treatment {
		background: $secondary-colour;
		padding: em(35);
	}


	&__treatment-title {
		color: $primary-colour;
		text-align: center;
	}


	@include media(880px){
		&__info {
			@include span-columns(3.5);
		}


		&__treatment {
			@include span-columns(8.5);
		}
	}

}