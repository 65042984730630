.two-image-block {
	text-align: center;

	&__image {
		display: inline-block;
		width: 11em;
		height: 4em;
		margin: 0 em(30);


		&.council {
			background:transparent url('../images/matthew-clark/council.png') center no-repeat;
		  background-size: contain;
		}



		&.association {
			background:transparent url('../images/matthew-clark/association.png') center no-repeat;
		  background-size: contain;
		}
	}
}