.global-header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 500;
	background: white;
	border-top: em(8) solid $primary-colour;
	border-left: em(8) solid $primary-colour;
	border-right: em(8) solid $primary-colour;
	box-shadow: 0px 29px 69px -34px rgba(0,0,0,0.25);


	.container {
		padding: 0 em(25);
	}


	.desktop-navigation {
		display: none;
	}



	@include media(1050px){



		.mobile-toggle {
			display: none;
		}

		.desktop-navigation {
			display: block;
			position: relative;
			top: -2.4em;
		}

		.mobile-header__contact-row {
			padding-top: 0.7em;
			clear: none;
			text-align: right;
		}


		.mobile-header__contact-email {
			margin-right: em(20, 16);
		}



	}


}





.global-header__title-row {
	padding-top: 1.4em;
	

	.global-logo {
		float: left;
	}


	.mobile-toggle {
		float: right;
		margin-top: 0.6em;
	}
}









.mobile-header__contact-row {
	font-size: em(16);
	padding-top: em(20, 16);
	font-weight: $fw-semibold;
	clear: both;
	margin-bottom: em(30);

	
	.mobile-header__contact-email,
	.mobile-header__contact-tel {
		color: $primary-colour;
	}



	.mobile-header__contact-tel {
		float: right;
	}
}



.mobile-header__linkedin {
	display: inline-block;
	height: 1.35em;
	width: 1.35em;
	margin-right: .75em;
	@include bg-icon("linkedin");
	position: relative;
	top: -3px;
}


.mobile-header__contact-email {
	position: relative;
	top: -7px;
}



.mobile-toggle {
	font-size: em(26);
	font-weight: $fw-medium;
	cursor: pointer;

	&__button {
		display: inline-block;
		width: em(22);
		height: em(11);
		margin-bottom: 0.05em;
		margin-right: .5em;
		@include bg-icon("menu-open");
	}

	&.is-open {
		color: $primary-colour;

		.mobile-toggle__button {
			@include bg-icon("menu-close");
		}
	}
}