.floating-feature {

  &__block {
	padding: 0 em(25) em(25);
  }

  &__item {
	text-align: center;
	padding: em(50) em(25);
	background: white;
	@include subtle-shadow();
	margin-bottom: 1em;
	p {
	  text-align: left;
	}
  }

  &__icon {
	background: $secondary-colour;
	display: inline-block;
	border-radius: 100%;
	height: em(90);
	width: em(90);
	margin-bottom: em(38);
	opacity: 0;
	transform: scale(.5);
	transition: all .4s ease-in-out;
	text-align: center;

	&.is-showing {
	  opacity: 1;
	  transform: scale(1);
	}

	span {
	  display: inline-block;
	  height: em(40);
	  width: em(40);
	  margin-top: 1.2em;
	}

	.therapy {
	  @include bg-icon("paintbrus-icon");
	}

	.supervision {
	  @include bg-icon("supervision-icon");
	}

	.training {
	  @include bg-icon("training-icon");
	}
  }

  &__title {
	color: $primary-colour;
  }

  //&__text {
  //	text-align: left;
  //}

  @include media(900px) {

	&__item {
	  @include span-columns(4);
	  margin-top: em(-80);
	}
  }
}