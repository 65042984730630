.slider-box {
	height: em(400);
	position: relative;

	&__previous,
	&__next {
		position: absolute;
		top: 50%;
		margin-top: -1.25em;
		height: 2.5em;
		width: 2.5em;
		cursor: pointer;
		z-index: 5;
	}


	&__previous {
		left: 1em;
		@include bg-icon('left-arrow');
	}



	&__next {
		right: 1em;
		@include bg-icon('right-arrow');
	}


	&__image {
		opacity: 0;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
		background-position: center;
		transition: opacity 1.5s ease-in-out;
		background-repeat: no-repeat;

		&.visible {
			opacity: 1;
		}
	}
}