/* ==========================================================================
Form, Label and Legend & Inputs
========================================================================== */
/* All rules within this section are opinionated. They can be removed or altered
without cross-browser rendering being affected */

form {
	// font-family: $form-font;
}

label {
	display: inline-block;
}

legend {
	display: block;
}






/* ==========================================================================
Buttons and Button-Like Inputs
========================================================================== */

/**
 * 1. Background needs to be defined so that is consistent across browsers
 *
 * 2. Border definition added to prevent different styling across browsers,
 *    also, it's a good idea to be consistent with whether buttons/inputs
 *    are going to have borders otherwise they will become difficult to size
 *    equally.
 *
 * 3. Radius added to prevent default styling by Safari on iOS.
 *
 * 4. Make alignment consistent
 *
 * 5. Stop browsers styling buttons by defauly with that horrible gradient!
 *
 */

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
	
	background: none; /* 1 */
	border: .1em solid $tertiary-colour; /* 2 */
	border-radius: 1.5em; /* 3 */
	text-align: center; /* 4 */
	-webkit-appearance: none; /* 5 */
	-moz-appearance: none; /* 5 */
	appearance: none; /* 5 */
	font-family: $body-font;
	font-size: em(20);
	font-weight: bold;
	

// ---------- Everything below this line is an opinionated choice -----------
	color: $tertiary-colour;
	white-space: nowrap;
	margin-bottom: 0;
	padding: em(15, 24) em(25, 24) em(15, 24);
	cursor: pointer;
	display: block;
	margin-left: auto;
	margin-right: auto;
	max-width: 9em;

	// width: 100%;

	&:hover,
	&:focus {
		outline: none;
	}
}



/* ==========================================================================
Inputs
========================================================================== */

/**
 * 1. Background needs to be defined so that is consistent across browsers
 *
 * 2. Border definition added to prevent different styling across browsers,
 *    also, it's a good idea to be consistent with whether buttons/inputs
 *    are going to have borders otherwise they will become difficult to size
 *    equally.
 *
 * 3. Radius added to prevent default styling by Safari on iOS.
 *
 * 4. Make alignment consistent
 *
 * 5. Stop browsers styling buttons by defauly with that horrible gradient!
 *
 * 6. Keeps textarea responsive even when cols attribute set.
 *
 */

input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
input[type='url'],
textarea,
select {
	background: white; /* 1 */
	border: .1em solid black; /* 2 */
	border-radius: 1em; /* 3 */
	text-align: left; /* 4 */

	-webkit-appearance: none; /* 5 */
	-moz-appearance: none; /* 5 */
	appearance: none; /* 5 */
	box-shadow: none; /* 5 */

	max-width: 100%; /* 6 */


	// ---------- Everything below this line is an opinionated choice -----------
	display: inline-block;
	padding: 0.7em;
	color: inherit;
	margin-bottom: em(40);

	&:hover,
	&:focus {
		outline: none;
	}
}


input[type=number] {
    -moz-appearance:textfield;
    width: 4.2em;
    position: relative;
    top: -4px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* ==========================================================================
Input Placeholders
========================================================================== */
/**
 * 1. Set placeholder text to same opacity across browsers.
 */

::-webkit-input-placeholder {
   color: black;
   opacity: 1; /* 1 */
}

:-moz-placeholder { /* Firefox 18- */
   color: black;
   opacity: 1; /* 1 */
}

::-moz-placeholder {  /* Firefox 19+ */
   color: black;
   opacity: 1; /* 1 */
}

:-ms-input-placeholder {  
   color: black;
   opacity: 1; /* 1 */
}
