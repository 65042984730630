/* 
Only found on the index page in the whole site


<section class="art-examples">
	<div class="container">
		<div class="art-examples__row row">
			<div class="art-examples__block">
				<div class="art-examples__image"></div>
				<span class="art-examples__line">|</span>
				<p class="art-examples__text">blah mcblah doogy</p>
			</div>
			<div class="art-examples__block">
				<div class="art-examples__image"></div>
				<span class="art-examples__line">|</span>
				<p class="art-examples__text">blah mcblah doogy</p>
			</div>
			<div class="art-examples__block">
				<div class="art-examples__image"></div>
				<span class="art-examples__line">|</span>
				<p class="art-examples__text">blah mcblah doogy</p>
			</div>
			<div class="art-examples__block">
				<div class="art-examples__image"></div>
				<span class="art-examples__line">|</span>
				<p class="art-examples__text">blah mcblah doogy</p>
			</div>
		</div>
	</div>
</section>
 */

.art-examples {
  padding: 0 em(25);
  @include bg-pattern('pattern.png');

  &__row {
	margin-bottom: em(-80);
  }

  &__block {
	text-align: center;
	@include span-columns(6);
	@include omega(2n);
	position: relative;
	top: 0;
	opacity: 0;
	transition: all .2s ease-in-out;

	&.is-showing {
	  top: em(-80);
	  opacity: 1;
	}
  }

  &__line {
	display: block;
	margin-top: -6px;
  }

  &__image {
	//height: em(160);
	display: block;
	position: relative;

	img { display: block; }

	&:after {
	  content: "";
	  display: inline-block;
	  background: $primary-colour;
	  opacity: .5;
	  position: absolute;
	  top: 0;
	  left: 0;
	  right: 0;
	  bottom: 0;
	}
  }

  &__text { }

  @include media(940px) {
	&__block {
	  @include omega-reset(2n);
	  @include span-columns(3);
	}
  }
}